import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  
  constructor(private titleService: Title, private metaService: Meta) { }
  setMetaData(title: string, description: string, keywords: string): void {
    // Set the page title
    this.titleService.setTitle(title);

    // Update or create the meta tags
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }
}
